<template>
  <svg height="6" width="13" :class="balloonArrowClass">
    <polygon points="6,1 12,6 1,6" style="stroke-width: 1;" />
  </svg>
</template>

<script>
import { BalloonArrowPosition, BALOON_ARROW } from '@/constants'

const CSN_BALLOON_ARROW_CLASS = 'csn-balloon-arrow'
const positionClass = {
  [BalloonArrowPosition.TOP_LEFT]: 'csn-balloon-arrow-top-left',
  [BalloonArrowPosition.BOTTOM_LEFT]: 'csn-balloon-arrow-bottom-left',
}

export default {
  name: BALOON_ARROW,
  props: {
    arrowPosition: {
      type: String,
      default: BalloonArrowPosition.TOP_LEFT,
    },
  },
  computed: {
    balloonArrowClass() {
      return `${CSN_BALLOON_ARROW_CLASS} ${positionClass[this.arrowPosition]}`
    },
  },
}
</script>
